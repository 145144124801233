export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
    access: "menu",
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    link: "/",
    access: "dashboard",
  },
  {
    id: 6,
    label: "menuitems.customers.text",
    icon: "uil-users-alt",
    access: "customers",
    subItems: [
      {
        id: 7,
        label: "menuitems.customers.list.users",
        link: "/customers",
        parentId: 6,
        access: "customers_users",
      },
      {
        id: 8,
        label: "menuitems.customers.list.groups",
        link: "/customers/groups",
        parentId: 6,
        access: "customers_users",
      },
      {
        id: 9,
        label: "menuitems.customers.list.customers_requests",
        link: "/customers/requests",
        parentId: 6,
        access: "customers_requests",
      },
      {
        id: 10,
        label: "menuitems.customers.list.optins",
        link: "/customers/optins",
        parentId: 6,
        access: "customers_optins",
      },
    ],
  },
  {
    id: 101,
    label: "menuitems.teams.text",
    icon: "uil-football",
    access: "teams",
    subItems: [
      {
        id: 102,
        label: "menuitems.teams.list.list",
        link: "/teams/list",
        parentId: 101,
        access: "teams_list",
      },
      {
        id: 103,
        label: "menuitems.teams.list.squad",
        link: "/teams/squad",
        parentId: 101,
        access: "teams_squad",
      },
      {
        id: 104,
        label: "menuitems.teams.list.list_sport",
        link: "/teams/list_sport",
        parentId: 101,
        access: "list_sport",
      },
    ],
  },
  {
    id: 110,
    label: "menuitems.fixtures.text",
    icon: "uil-calendar-alt",
    access: "fixtures",
    subItems: [
      {
          id: 111,
          label:"menuitems.fixtures.list.current",
          link:"/fixtures/current",
          parentId: 110,
          access:"fixtures_current",
      },
      {
        id: 112,
        label: "menuitems.fixtures.list.feature_matches",
        link: "/fixtures/featured_matches",
        parentId: 110,
        access: "fixtures_feature_matches",
      },
      {
        id: 113,
        label: "menuitems.fixtures.list.rounds",
        link: "/fixtures/rounds",
        parentId: 110,
        access: "fixtures_rounds",
      },
      {
        id: 114,
        label: "menuitems.fixtures.list.standings",
        link: "/fixtures/standings",
        parentId: 110,
        access: "fixtures_standings",
      },
      {
        id: 115,
        label: "menuitems.fixtures.list.sm",
        link: "/fixtures/matches",
        parentId: 110,
        access: "fixtures_sim",
      },
      {
        id: 116,
        label: "menuitems.fixtures.list.sm_new",
        link: "/fixtures/matches_new",
        parentId: 110,
        access: "fixtures_sim_new",
      },


      {
        id: 116,
        label:"menuitems.fixtures.list.competition",
        link:"/fixtures/competition",
        parentId: 110,
        access:"fixtures_competition",
      }
    ],
  },
  {
    id: 120,
    label: "menuitems.news.text",
    icon: "uil-rss",
    access: "news",
    subItems: [
      {
        id: 122,
        label: "menuitems.news.list.feed",
        link: "/news/feed",
        parentId: 120,
        access: "news_feed",
      },
      {
        id: 121,
        label: "menuitems.news.list.videos",
        link: "/news/videos",
        parentId: 120,
        access: "news_videos",
      },
      {
        id: 123,
        label: "menuitems.news.list.shorts",
        link: "/news/shorts",
        parentId: 120,
        access: "shorts",
      },
    ],
  },
  {
    id: 130,
    label: "menuitems.ticketing.text",
    icon: "uil-ticket",
    access: "ticketing",
    subItems: [
      {
        id: 131,
        label: "menuitems.ticketing.list.seasontickets",
        link: "/ticketing/season_tickets",
        parentId: 130,
        access: "ticketing_season_tickets",
      },
      {
        id: 132,
        label: "menuitems.ticketing.list.matchtickets",
        link: "/ticketing/match_tickets",
        parentId: 130,
        access: "ticketing_match_tickets",
      },
      {
        id: 133,
        label: "menuitems.ticketing.list.tickets_matches",
        link: "/ticketing/tickets_matches",
        parentId: 130,
        access: "ticketing_tickets_matches",
      },
      {
        id: 134,
        label: "menuitems.ticketing.list.axess_setup",
        link: "/ticketing/axess_setup",
        parentId: 130,
        access: "axess_setup",
      },
      {
        id: 135,
        label: "menuitems.ticketing.list.season_products",
        link: "/ticketing/season_products",
        parentId: 130,
        access: "season_products",
      },

        // {
        //     id: 133,
        //     label: "menuitems.ticketing.list.tickets_match_details",
        //     link: "/ticketing/tickets_matches",
        //     parentId: 130,
        //     access: "ticketing_tickets_match_details",
        // },


    ],
  },
  {
    id: 140,
    label: "menuitems.games.text",
    icon: "uil-trophy",
    access: "games",
    subItems: [
      // {
      //     id: 141,
      //     label: "menuitems.games.list.ptw",
      //     link: "/games/player_of_the_week",
      //     parentId: 140
      // },
      {
        id: 142,
        label: "menuitems.games.list.gts",
        link: "/games/guess_the_score",
        parentId: 140,
        access: "games_gts",
      },
      {
        id: 143,
        label: "menuitems.games.list.motm",
        link: "/games/man_of_the_match",
        parentId: 140,
        access: "games_motm",
      },
      {
        id: 144,
        label: "menuitems.games.list.leaderboard",
        link: "/games/leaderboard",
        parentId: 140,
        access: "games_leaderboard",
      },
      {
        id: 145,
        label: "menuitems.games.list.mini_games",
        link: "/games/mini_games",
        parentId: 140,
        access: "games_mini_games",
      },
      {
        id: 146,
        label: "menuitems.games.list.social",
        link: "/games/social",
        parentId: 140,
        access: "games_social",
      },
    ],
  },
  {
    id: 150,
    label: "menuitems.advertising.text",
    icon: "uil-file-contract-dollar",
    access: "advertising",
    subItems: [
      {
        id: 151,
        label: "menuitems.advertising.list.advertisements",
        link: "/advertising/advertisements",
        parentId: 150,
        access: "advertising_advertisements",
      },
      {
        id: 152,
        label: "menuitems.advertising.list.banner",
        link: "/advertising/banner",
        parentId: 150,
        access: "advertising_banner",
      },
      {
        id: 153,
        label: "menuitems.advertising.list.advertorial",
        link: "/advertising/advertorial",
        parentId: 150,
        access: "advertising_advertorial",
      },
      {
        id: 154,
        label: "menuitems.advertising.list.sponsors",
        link: "/advertising/sponsors",
        parentId: 150,
        access: "advertising_sponsors",
      },
      {
        id: 155,
        label: "menuitems.advertising.list.deals",
        link: "/advertising/deals",
        parentId: 150,
        access: "advertising_deals",
      },
      {
        id: 156,
        label: "menuitems.advertising.list.app_ads",
        link: "/advertising/app_ads",
        parentId: 150,
        access: "advertising_app_ads",
      },
    ],
  },
  {
    id: 160,
    label: "menuitems.notifications.text",
    icon: "uil-message",
    link: "/notifications",
    access: "notifications",
  },
  {
    id: 170,
    label: "menuitems.orders.text",
    icon: "uil-arrow-growth",
    link: "/orders",
    access: "orders",
  },
  {
    id: 175,
    label: "menuitems.merchandising.text",
    icon: "uil-shopping-cart-alt",
    access: "merchandising",
    subItems: [
      {
        id: 176,
        label: "menuitems.merchandising.list.products",
        link: "/merchandising/products",
        parentId: 175,
        access: "merchandising_products",
      },
      {
        id: 177,
        label: "menuitems.merchandising.list.media",
        link: "/merchandising/media",
        parentId: 175,
        access: "merchandising_media",
      },
      {
          id: 178,
          label: "menuitems.merchandising.list.promotions",
          link: "/merchandising/promotions",
          parentId: 175,
          access: "merchandising_promotions",
      },
    ],
  },

  //only for monitoring
  {
    id: 179,
    label: "menuitems.monitor.text",
    icon: "mdi mdi-view-week",
    link: "/monitor/queues",
    access: "queues"
  },
  {
    id: 180,
    label: "menuitems.application.list.application_params",
    icon: "mdi-apps",
    link: "/application/params",
    access: "application_params"
  },
  {
    id: 181,
    label: "menuitems.application.text",
    icon: "uil-monitor",
    access: "application",
    subItems: [
        {
            id: 182,
            label: "menuitems.application.list.application",
            link: "/application/access",
            parentId: 183,
            access: "application_access",
        },
    ],
  },
  {
    id: 184,
    label: "menuitems.terms_and_conditions.text",
    icon: "fas fa-file-contract",
    link: "/monitor/terms_and_conditions",
    access: "terms_and_conditions"
  },
  {
    id: 192,
    label: "menuitems.dashboards.text",
    icon: "uil-home-alt",
    link: "/monitor/dashboards",
    access: "dashboards"
  },
  {
    id: 185,
    label: "menuitems.logs.text",
    icon: "mdi mdi-text-box-search",
    access: "logs",
    subItems: [
      {
        id: 186,
        label: "menuitems.logs.list.api_errors",
        link: "/monitor/logs/api_errors",
        parentId: 185,
        access: "logs_api_errors",
      },
      {
        id: 187,
        label: "menuitems.logs.list.cron_logs",
        link: "/monitor/logs/api_cron_logs",
        parentId: 185,
        access: "logs_api_cron_logs",
      },
      {
        id: 188,
        label: "menuitems.logs.list.exceptions",
        link: "/monitor/logs/api_exceptions",
        parentId: 185,
        access: "logs_api_exceptions",
      },
      {
        id: 189,
        label: "menuitems.logs.list.api_alerts",
        link: "/monitor/logs/api_alerts",
        parentId: 185,
        access: "logs_api_alerts",
      },
     
    ],
  },
  {
    id: 220,
    label: "menuitems.rbac_setup.text",
    icon: "mdi mdi-wrench",
    access: "rbac_setup",
    subItems: [
      {
        id: 221,
        label: "menuitems.rbac_setup.list.dashboard_access_setup",
        link: "/auth/rbac_setup/dashboard_access_setup",
        parentId: 220 ,
        access: "dashboard_access_setup",
      },
      {
        id: 222,
        label: "menuitems.rbac_setup.list.api_access_setup",
        link: "/auth/rbac_setup/api_access_setup",
        parentId: 220,
        access: "api_access_setup",
      },
    ]
  },
  {
    id: 210,
    label: "menuitems.rbac.text",
    icon: "mdi mdi-view-week",
    access: "rbac",
      subItems: [
          {
              id: 211,
              label: "menuitems.rbac.list.dashboard_access",
              link: "/monitor/rbac/dashboard_access",
              parentId: 210 ,
              access: "rbac_dashboard_access",
          },
          {
              id: 212,
              label: "menuitems.rbac.list.api_access",
              link: "/monitor/rbac/api_access",
              parentId: 210,
              access: "rbac_api_access",
          },
          {
              id: 213,
              label: "menuitems.rbac.list.rbac_access",
              link: "/monitor/rbac/rbac_access",
              parentId: 210,
              access: "rbac_access",
          },
          ]
  },
  {
    id: 190,
    label: "menuitems.financials.text",
    icon: "uil-moneybag",
    access: "financials",
    subItems: [
      {
        id: 172,
        label: "menuitems.financials.list.transactions",
        link: "/financials",
        parentId: 190,
        access: "financials_transactions",
      },
      {
        id: 173,
        label: "menuitems.financials.list.stripe_payouts",
        link: "/financials/stripe_payouts",
        parentId: 190,
        access: "stripe_payouts",
      },

    ],
  },
  {
    id: 191,
    label: "menuitems.cashless.text",
    icon: "uil-credit-card",
    access: "cashless",
    subItems: [
      {
        id: 173,
        label: "menuitems.cashless.list.cards_management",
        link: "/cashless",
        parentId: 191,
        access: "cashless_cards_management",
      },
      {
        id: 174,
        label: "menuitems.cashless.list.topup",
        link: "/topup",
        parentId: 191,
        access: "cashless_topup",
      },
    ],
  },
  {
    id: 200,
    label: "menuitems.app.text",
    icon: "uil-mobile-android",
    access: "app",
    subItems: [
        {
            id: 201,
            label: "menuitems.app.list.popup",
            link: "/app/popup",
            parentId: 200,
            access: "app_popup",
        },
        {
            id: 202,
            label: "menuitems.app.list.app_params",
            link: "/app/app_params",
            parentId: 200,
            access: "app_params",
        },
        {
            id: 203,
            label: "menuitems.app.list.app_crm_mimic",
            link: "/app/app_crm_mimic",
            parentId: 200,
            access: "app_crm_mimic",
        },
        {
          id: 204,
          label: "menuitems.app.list.settings",
          link: "/app/settings",
          parentId: 200,
          access: "settings",
        },
    ],
  },
    {
        id: 300,
        label: "menuitems.media.text",
        icon: "uil-image",
        access: "media",
        subItems: [
            {
                id: 301,
                label: "menuitems.media.list.media_management",
                link: "/media/media_management",
                parentId: 300,
                access: "media_management",
            },
        ],
    },
  {
    id: 310,
    label: "menuitems.social.text",
    icon: "uil-image",
    access: "social",
    subItems: [
      {
        id: 311,
        label: "menuitems.social.list.social_levels",
        link: "/social/social_levels",
        parentId: 310,
        access: "social_levels",
      },
      {
        id: 312,
        label: "menuitems.social.list.social_milestone",
        link: "/social/social_milestone",
        parentId: 310,
        access: "social_milestone",
      },
      {
        id: 313,
        label: "menuitems.social.list.social_xp",
        link: "/social/social_xp",
        parentId: 310,
        access: "social_xp",
      },
    ],
  },
  {
    id: 320,
    label: "menuitems.sport_data.text",
    icon: "uil-football",
    access: "sport_data",
    subItems: [
      {
        id: 321,
        label: "menuitems.sport_data.list.tenant_setup",
        link: "/sport_data/tenant_setup",
        parentId: 310,
        access: "tenant_setup",
      },
      {
        id: 322,
        label: "menuitems.sport_data.list.tenant_tournament",
        link: "/sport_data/tenant_tournament",
        parentId: 310,
        access: "tenant_tournament",
      },
    ],
  },
  {
    id: 330,
    label: "menuitems.ssl.text",
    icon: "fas fa-link",
    link: "/ssl/ssl_links",
    access: "ssl_links"
  },

  /*
    
    {
        id: 3,
        label: "menuitems.apps.text",
        isTitle: true,
        access: "template",
    },
    {
        id: 4,
        label: "menuitems.calendar.text",
        icon: "uil-calender",
        link: "/calendar",
        access: "template",
    },
    {
        id: 5,
        label: "menuitems.chat.text",
        icon: "uil-comments-alt",
        link: "/chat",
        access: "template",
        badge: {
            variant: "warning",
            text: "menuitems.chat.badge"
        }
    },
    {
        id: 100,
        label: "menuitems.ecommerce.text",
        icon: "uil-store",
        access: "template",
        subItems: [
            {
                id: 7,
                label: "menuitems.ecommerce.list.products",
                link: "/ecommerce/products",
                parentId: 6
            },
            {
                id: 8,
                label: "menuitems.ecommerce.list.productdetail",
                link: "/ecommerce/product-detail/1",
                parentId: 6
            },
            {
                id: 9,
                label: "menuitems.ecommerce.list.orders",
                link: "/ecommerce/orders",
                parentId: 6
            },
            {
                id: 10,
                label: "menuitems.ecommerce.list.customers",
                link: "/ecommerce/customers",
                parentId: 6
            },
            {
                id: 11,
                label: "menuitems.ecommerce.list.cart",
                link: "/ecommerce/cart",
                parentId: 6
            },
            {
                id: 12,
                label: "menuitems.ecommerce.list.checkout",
                link: "/ecommerce/checkout",
                parentId: 6
            },
            {
                id: 13,
                label: "menuitems.ecommerce.list.shops",
                link: "/ecommerce/shops",
                parentId: 6
            },
            {
                id: 14,
                label: "menuitems.ecommerce.list.addproduct",
                link: "/ecommerce/add-product",
                parentId: 6
            }
        ]
    },
    {
        id: 15,
        label: "menuitems.email.text",
        icon: "uil-envelope",
        access: "template",
        subItems: [
            {
                id: 16,
                label: "menuitems.email.list.inbox",
                link: "/email/inbox",
                parentId: 15
            },
            {
                id: 17,
                label: "menuitems.email.list.reademail",
                link: "/email/reademail/1",
                parentId: 15
            }
        ]
    },
    {
        id: 18,
        label: "menuitems.invoices.text",
        icon: "uil-invoice",
        access: "template",
        subItems: [
            {
                id: 19,
                label: "menuitems.invoices.list.invoicelist",
                link: "/invoices/list",
                parentId: 18
            },
            {
                id: 20,
                label: "menuitems.invoices.list.invoicedetail",
                link: "/invoices/detail",
                parentId: 18
            }
        ]
    },
    
   
    {
        id: 25,
        label: "menuitems.pages.text",
        isTitle: true,
        access: "template",
    },
    
    {
        id: 26,
        label: "menuitems.authentication.text",
        icon: "uil-user-circle",
        access: "template",
        subItems: [
            {
                id: 27,
                label: "menuitems.authentication.list.login",
                link: "/auth/login-1",
                parentId: 26
            },
            {
                id: 28,
                label: "menuitems.authentication.list.register",
                link: "/auth/register-1",
                parentId: 26
            },
            {
                id: 29,
                label: "menuitems.authentication.list.recoverpwd",
                link: "/auth/recoverpwd",
                parentId: 26
            },
            {
                id: 30,
                label: "menuitems.authentication.list.lockscreen",
                link: "/auth/lock-screen",
                parentId: 26
            }
        ]
    },
    {
        id: 31,
        label: "menuitems.utility.text",
        icon: "uil-file-alt",
        access: "template"
        subItems: [
            {
                id: 32,
                label: "menuitems.utility.list.starter",
                link: "/utility/starter",
                parentId: 31
            },
            {
                id: 33,
                label: "menuitems.utility.list.maintenance",
                link: "/utility/maintenance",
                parentId: 31
            },
            {
                id: 35,
                label: "menuitems.utility.list.comingsoon",
                link: "/utility/comingsoon",
                parentId: 31
            },
            {
                id: 36,
                label: "menuitems.utility.list.timeline",
                link: "/utility/timeline",
                parentId: 31
            },
            {
                id: 37,
                label: "menuitems.utility.list.faqs",
                link: "/utility/faqs",
                parentId: 31
            },
            {
                id: 38,
                label: "menuitems.utility.list.pricing",
                link: "/utility/pricing",
                parentId: 31
            },
            {
                id: 39,
                label: "menuitems.utility.list.error404",
                link: "/utility/404",
                parentId: 31
            },
            {
                id: 40,
                label: "menuitems.utility.list.error500",
                link: "/utility/500",
                parentId: 31
            }
        ]
    },
    {
        id: 41,
        label: "menuitems.components.text",
        isTitle: true,
        access: "template",
    },
    {
        id: 42,
        label: "menuitems.uielements.text",
        icon: "uil-flask",
        subItems: [
            {
                id: 43,
                label: "menuitems.uielements.list.alerts",
                link: "/ui/alerts",
                parentId: 42
            },
            {
                id: 44,
                label: "menuitems.uielements.list.buttons",
                link: "/ui/buttons",
                parentId: 42
            },
            {
                id: 45,
                label: "menuitems.uielements.list.cards",
                link: "/ui/cards",
                parentId: 42
            },
            {
                id: 46,
                label: "menuitems.uielements.list.carousel",
                link: "/ui/carousel",
                parentId: 42
            },
            {
                id: 47,
                label: "menuitems.uielements.list.dropdowns",
                link: "/ui/dropdown",
                parentId: 42
            },
            {
                id: 48,
                label: "menuitems.uielements.list.grid",
                link: "/ui/grid",
                parentId: 42
            },
            {
                id: 49,
                label: "menuitems.uielements.list.images",
                link: "/ui/images",
                parentId: 42
            },
            {
                id: 50,
                label: "menuitems.uielements.list.lightbox",
                link: "/ui/lightbox",
                parentId: 42
            },
            {
                id: 51,
                label: "menuitems.uielements.list.modals",
                link: "/ui/modals",
                parentId: 42
            },
            {
                id: 52,
                label: "menuitems.uielements.list.rangeslider",
                link: "/ui/rangeslider",
                parentId: 42
            },
            {
                id: 53,
                label: "menuitems.uielements.list.progressbar",
                link: "/ui/progressbar",
                parentId: 42
            },
            {
                id: 53,
                label: "menuitems.uielements.list.placeholder",
                link: "/ui/placeholder",
                parentId: 42
            },
            {
                id: 54,
                label: "menuitems.uielements.list.sweetalert",
                link: "/ui/sweet-alert",
                parentId: 42
            },
            {
                id: 55,
                label: "menuitems.uielements.list.tabs",
                link: "/ui/tabs-accordions",
                parentId: 42
            },
            {
                id: 56,
                label: "menuitems.uielements.list.typography",
                link: "/ui/typography",
                parentId: 42
            },
            {
                id: 57,
                label: "menuitems.uielements.list.video",
                link: "/ui/video",
                parentId: 42
            },
            {
                id: 58,
                label: "menuitems.uielements.list.general",
                link: "/ui/general",
                parentId: 42
            },
            {
                id: 59,
                label: "menuitems.uielements.list.colors",
                link: "/ui/colors",
                parentId: 42
            },
            {
                id: 60,
                label: "menuitems.uielements.list.rating",
                link: "/ui/rating",
                parentId: 42
            }
        ]
    },
    {
        id: 62,
        label: "menuitems.forms.text",
        icon: "uil-shutter-alt",
        access: "template",
        badge: {
            variant: "danger",
            text: 'menuitems.forms.badge'
        },
        subItems: [
            {
                id: 63,
                label: "menuitems.forms.list.elements",
                link: "/form/elements",
                parentId: 62
            },
            {
                id: 64,
                label: "menuitems.forms.list.validation",
                link: "/form/validation",
                parentId: 62
            },
            {
                id: 65,
                label: "menuitems.forms.list.advanced",
                link: "/form/advanced",
                parentId: 62
            },
            {
                id: 66,
                label: "menuitems.forms.list.editor",
                link: "/form/editor",
                parentId: 62
            },
            {
                id: 67,
                label: "menuitems.forms.list.fileupload",
                link: "/form/upload",
                parentId: 62
            },
            {
                id: 68,
                label: "menuitems.forms.list.repeater",
                link: "/form/repeater",
                parentId: 62
            },
            {
                id: 69,
                label: "menuitems.forms.list.wizard",
                link: "/form/wizard",
                parentId: 62
            },
            {
                id: 70,
                label: "menuitems.forms.list.mask",
                link: "/form/mask",
                parentId: 62
            }
        ]
    },
    {
        id: 71,
        icon: "uil-list-ul",
        label: "menuitems.tables.text",
        access: "template",
        subItems: [
            {
                id: 72,
                label: "menuitems.tables.list.basic",
                link: "/tables/basic",
                parentId: 71
            },
            {
                id: 73,
                label: "menuitems.tables.list.advanced",
                link: "/tables/advanced",
                parentId: 71
            }
        ]
    },
    {
        id: 74,
        icon: "uil-chart",
        label: "menuitems.charts.text",
        access: "template",
        subItems: [
            {
                id: 75,
                label: "menuitems.charts.list.apex",
                link: "/charts/apex",
                parentId: 74
            },
            {
                id: 76,
                label: "menuitems.charts.list.chartjs",
                link: "/charts/chartjs",
                parentId: 74
            },
            {
                id: 77,
                label: "menuitems.charts.list.chartist",
                link: "/charts/chartist",
                parentId: 74
            },
            {
                id: 78,
                label: "menuitems.charts.list.echart",
                link: "/charts/echart",
                parentId: 74
            }
        ]
    },
    {
        id: 79,
        label: "menuitems.icons.text",
        icon: "uil-streering",
        access: "template",
        subItems: [
            {
                id: 80,
                label: "menuitems.icons.list.unicons",
                link: "/icons/unicons",
                parentId: 79
            },
            {
                id: 81,
                label: "menuitems.icons.list.boxicons",
                link: "/icons/boxicons",
                parentId: 79
            },
            {
                id: 82,
                label: "menuitems.icons.list.materialdesign",
                link: "/icons/materialdesign",
                parentId: 79
            },
            {
                id: 83,
                label: "menuitems.icons.list.dripicons",
                link: "/icons/dripicons",
                parentId: 79
            },
            {
                id: 84,
                label: "menuitems.icons.list.fontawesome",
                link: "/icons/fontawesome",
                parentId: 79
            }
        ]
    },
    {
        id: 85,
        label: "menuitems.maps.text",
        icon: "uil-location-point",
        access: "template",
        subItems: [
            {
                id: 86,
                label: "menuitems.maps.list.googlemap",
                link: "/maps/google",
                parentId: 85
            },
            {
                id: 87,
                label: "menuitems.maps.list.leafletmap",
                link: "/maps/leaflet",
                parentId: 85
            },
        ]
    },
    {
        id: 88,
        label: "menuitems.multilevel.text",
        icon: "uil-share-alt",
        access: "template",
        subItems: [
            {
                id: 89,
                label: "menuitems.multilevel.list.level1.1",
                link: "#",
                parentId: 88
            },
            {
                id: 90,
                label: "menuitems.multilevel.list.level1.2",
                parentId: 88,
                subItems: [
                    {
                        id: 91,
                        label: "menuitems.multilevel.list.level1.level2.1",
                        link: "#",
                        parentId: 88
                    },
                    {
                        id: 92,
                        label: "menuitems.multilevel.list.level1.level2.2",
                        link: "#",
                        parentId: 88
                    }
                ]
            }
        ]
    }
    
    */
];
